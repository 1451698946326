var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f31fa530b9dc65ee942882d8061afe859bdc5910"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const IgnoreSentryMessage = [
  // Apollo Errors when a token is expired and new token is to be fetched
  "Access denied for field 'user' on type 'UserMutationType'",
  // "Cloudflare Turnstile",
  "Unknown root exit status",
  "Unknown root exit status.",
  "Load failed",
  "Failed to fetch",

];

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler: (samplingContext) => {
    const txnName = samplingContext.transactionContext.name;

    // Very low sampling for ignored errors
    if (txnName?.includes('fetch') ||
      // txnName?.includes('turnstile') ||
      txnName?.includes('chrome-extension')) {
      return 0.1;
    }

    // Default sampling for other spans
    return 0.2;
  },

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  beforeSend(event, hint) {
    if (!hint?.originalException) {
      return event;
    }
    const exception = hint.originalException as Error | { message: string } | null;
    if (!exception || typeof exception !== 'object' || !('message' in exception)) {
      return event;
    }

    if (IgnoreSentryMessage.some(msg => exception.message?.includes(msg))) {
      return null;
    }

    return event;
  },

  ignoreErrors: [
    // General Javascript Errors
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
    // following 2 errors are handled by src/utils/lazyCatch.ts
    "Unexpected token '<'",
    "Element type is invalid. Received a promise that resolves to: undefined. Lazy element type must resolve to a class or function.",
    "Event `CloseEvent` (type=close) captured as promise rejection",
    "Event `Event` (type=error) captured as promise rejection",
    // https://stackoverflow.com/questions/76697631/cannot-read-properties-of-undefined-reading-firefoxsample
    "Cannot read properties of undefined (reading 'firefoxSample')",

    // Prokjet Errors

    // "[Cloudflare Turnstile] Error: 300030",
    // "TurnstileError",
    // "Turnstile Widget seem to have crashed",
    "Unknown root exit status",
    "Unknown root exit status.",
    "Load failed",
    "Failed to fetch",

    // Typically the AggregateError occurs when there is build to prod and use occurs multiple errors because of the old build
    "AggregateError",

    // https://github.com/getsentry/sentry-javascript/issues/2546
    "Event `Event` (type=error) captured as promise rejection",
    "Event `CloseEvent` (type=close) captured as promise rejection"
  ],
  denyUrls: [
    // https://docs.sentry.io/clients/javascript/tips/
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
});
